#chat {
    margin: 0 10px 10px 10px;
    overflow: auto;
    height: 100%
}

.form input[type="radio"]{
    margin-right: 5px;
}

.flex-label {
    /*align-items: center;*/
    margin-bottom: 4px;
    display: flex;
}
