#legal-text {
    background-color: #F7F4F4;
    /*display: inline;*/
    box-sizing: border-box;
    text-align: justify;
    /*position:fixed;*/
    margin: auto;
    padding: 10px;
    bottom: 60px;
    width: 100%;
    z-index:100;
}

#legal-text-link {
}
