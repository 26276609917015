.send-message-bar {
    /*width: 100%;*/
    height: 60px;
    display: flex;
    /*flex-direction: row;*/
    background-color: #dbdbdb;
}

.send-message-bar-left {
    width: 20px;
}

.send-message-bar-center {
    flex: 1 100%;
    margin: auto;
}

.send-message-bar-text {
    width: 100%;
    border: none;
    border-radius: 6px;
    height: 45px;
    /*padding: 5px 10px;*/
    font-size: 14px;
    font-weight: 400;
}

input[type="text"]:disabled::placeholder {
    color: #c9c9c9;
}

.send-message-bar-right {
    width: 60px;
    margin-left: 5px;
}

.btn-send {
    border: none;
    background-color: #dbdbdb;
    height: 100%;
    width: 60px;
    display: flex;
}

.btn-send:hover,
.btn-send:focus {
    cursor: pointer;
    outline: none;
}

.btn-send:hover .btn-img {
    /* #7F54B4 */
    filter: invert(32%) sepia(92%) saturate(552%) hue-rotate(228deg) brightness(95%)
}

.btn-img {
    /* 6E469F */
    filter: invert(31%) sepia(18%) saturate(2887%) hue-rotate(231deg) brightness(91%) contrast(85%);
    margin: auto;
    height: 70%;
    width: 70%;
}


