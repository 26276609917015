#information {
    background-color: #6b479e;
    box-sizing: border-box;
    font-weight: bold;
    color: white;
    height: 60px;
    width: 100%;
    z-index:100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

#information p {
    margin: 5px;
    text-align: center;
    font-size: 1em;
}
