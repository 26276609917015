#typing {
    /*background-color: #6b479e;*/
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    text-align: center;
    position:fixed;
    vertical-align: bottom;
    margin-top: auto;
    margin-left: 5px;
    bottom: 60px;
    width: 100%;
    z-index:90;
}

#typing-text {
    color: #6b479e;
    font-weight: bolder;
    font-size: large;
    margin-left: 5px;
}

div#wave {
    margin-left: 5px;
    position: relative;
    text-align: center;
    width: fit-content;
}
div#wave .dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 2px;
    background: #6b479e;
    animation: wave 1s linear infinite;
}
div#wave .dot:nth-child(2) {
    animation-delay: -2.2s;
}
div#wave .dot:nth-child(3) {
    animation-delay: -1.3s;
}
@keyframes wave {
    0%, 60%, 100% {
        transform: initial;
    }
    30% {
        transform: translateY(-8px);
    }
}
