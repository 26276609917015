.chat-message-container {
    padding-top: 10px;
}

.message-content {
    max-width: 66%;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    padding: 10px;
}

.chat-message-L {
    /*float: left;*/
    background-color: #dbdbdb;
    color: #282c34;
    width: 100%;
}

.chat-message-L .link-button{
    color: #6b479e;
}

.chat-message-L-form {
    width: 100%;
    margin-left: 50px; /* Icon container width*/
    margin-top: -10px;
    margin-bottom: 0;
}

.chat-message-L-typing {
    width: fit-content;
    margin-left: 50px; /* Icon container width*/
    margin-top: -10px;
    margin-bottom: 0;
    background-color: #f1f1f1;
    border-radius: 20px;
}

input[type="radio"] {
}

input[type="radio"]:checked {
}

.chat-message-R {
    background-color: #6b479e;
    color: white;
    margin-left:auto !important;
    margin-right:0;
    width: 100%;
}

.message-timestamp {
    margin-top: 8px;
    font-size: 9px;
    text-align: right;
}

.link-button {
    font-size: 15px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
}

.icon-agent-container {
    /*float: left;*/
    display: flex;
    border-radius: 50%;
    background-color: #6b479e;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}
.icon-agent {
    /* #fff */
    filter: invert(98%) sepia(1%) saturate(212%) hue-rotate(171deg) brightness(117%) contrast(100%);
    margin: auto;
    width: 60%;
    height: 60%;
}

.message {
    display: flex;
}

input[type=text], input[type=textarea] {
    width: 100%;
    padding: 12px 6px;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

select {
    width: 100%;
    padding: 12px 6px;
    margin: 4px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
