.App {
  height: calc(100% - 130px);
}

.App .topArea {
  display: flex;
  box-sizing: border-box;
  background-color: #6b479e;
  text-align: left;
  align-items: center;
  font-weight: bold;
  padding: 20px 0 20px 20px;
  color: white;
  height: 60px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App #poweredRainbow {
  max-height: 15px;
  font-style: italic;
  bottom: 5px;
  text-align: center;
}

.App #rainbowLogo {
  padding-left: 10px;
  max-height: 15px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
